import Link from 'next/link';
import {darken} from 'polished';
import React from 'react';
import styled from 'styled-components';
import {HeadingM, Text} from '../Typography';

interface StoreCardProps {
  store: Store;
}

const StoreCard = ({store}: StoreCardProps) => {
  const placeHolder = store.name
    .split(' ')
    .map((item) => item[0])
    .join('');

  return (
    <StoreContainer bg={store.background}>
      <Link href={`/${store.slug}`} title={store.name}>
        <StoreContent>
          {!store.background && <Placeholder>{placeHolder}</Placeholder>}
          <Title>
            <HeadingM>{store.name}</HeadingM>
            <TextExtended>Entra</TextExtended>
          </Title>
        </StoreContent>
      </Link>
    </StoreContainer>
  );
};

export default StoreCard;

const StoreContainer = styled.div<{bg: string | null}>`
  width: calc(
    20% - ${(props) => props.theme.layout.frontendSpace} * 2 + 16px / 4
  );
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 5px;
  background-image: ${(props) => (props.bg ? `url(${props.bg})` : 'none')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) => darken(0.2, props.theme.colors.main)};
  margin-right: calc(${(props) => props.theme.layout.frontendSpace} * 2);
  margin-bottom: calc(${(props) => props.theme.layout.frontendSpace} * 2);
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  position: relative;
  transition: box-shadow 0.3s;

  a {
    text-decoration: none;
    width: 100%;
    height: 100%;
  }

  &:nth-child(5n) {
    margin-right: 0;
  }

  @media ${(props) => props.theme.responsive.desktopSmall} {
    width: calc(
      50% - ${(props) => props.theme.layout.frontendSpace} * 2 + 16px
    );
    height: auto;

    &:nth-child(5n) {
      margin-right: calc(${(props) => props.theme.layout.frontendSpace} * 2);
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media ${(props) => props.theme.responsive.phone} {
    width: 100%;
    min-height: 50vw;
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.2);
  }
`;

const StoreContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 23vw;
  flex-direction: column;
  justify-content: flex-end;
`;

const Placeholder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  color: #fff;
  flex: 1;
  padding: 3px;
  font-size: clamp(1rem, 2.5vw, 1.7rem);

  @media ${(props) => props.theme.responsive.tablet} {
    font-size: 1.6rem;
  }

  @media ${(props) => props.theme.responsive.phone} {
    min-height: 30vw;
  }
`;

const Title = styled.div`
  background-color: #fff;
  padding: ${(props) => props.theme.layout.frontendSpace};
  border-radius: 0 0 5px 5px;

  h2 {
    margin: 0;
    line-height: 1.2;
    font-size: clamp(1rem, 1.4vw, 1.7rem);
    max-height: 120px;

    display: -webkit-box; /* stylelint-disable-line */
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media ${(props) => props.theme.responsive.tablet} {
      font-size: 1.6rem;
      max-height: inherit;
      white-space: normal;
    }
  }
`;

const TextExtended = styled(Text)`
  margin: 0;
`;
