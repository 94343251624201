import React from 'react';
import {NextPageContext} from 'next';
import Head from 'next/head';
import styled from 'styled-components';
import AdminBar from '../components/AdminBar';
import {Page, PageInside} from '../components/Lobby/layout';
import Legal from '../components/Legal';
import StoreCard from '../components/RoomSelection/Card';
import defaultLogo from '../assets/images/logo-default.svg';
import {darken} from 'polished';
import {fetchCompanyConfig} from '../utils/api';
import Router from 'next/router';

export default function IndexPage({company}: {company: Company}) {
  const logoImage = company.logo || defaultLogo.src;
  return (
    <>
      <Head>
        <title>{company.name || 'Youmain'} - Scegli una stanza</title>
        <meta
          property="og:title"
          content={`${company.name || 'Youmain'} - Scegli una stanza`}
          key="title"
        />
        <meta
          property="og:description"
          content="Scegli una stanza"
          key="description"
        />
      </Head>

      <AdminBar />

      <PageExtended>
        <PageInside>
          <Logo src={logoImage} alt={company.name} />
          <Stores>
            {company.stores?.map((store) => (
              <StoreCard store={store} key={store.id} />
            ))}
          </Stores>
          <Legal />
        </PageInside>
      </PageExtended>
    </>
  );
}

IndexPage.getInitialProps = async function (ctx: NextPageContext): Promise<{}> {
  const host = ctx?.req?.headers?.host || window.location.host;

  let company = ctx.currentCompany;

  if (!company) {
    try {
      company = await fetchCompanyConfig(host);
    } catch (e) {}
  }

  if (!company) {
    if (ctx.res) {
      ctx.res.statusCode = 404;
    }

    return {
      errorCode: 404,
    };
  }

  if (company.homeConfig.type === 'store') {
    const store = company.stores.find((s) => s.id === company.homeConfig.store);

    if (ctx.res) {
      ctx.res.writeHead(302, {
        Location: '/' + store.slug,
      });

      ctx.res.end();
    } else {
      Router.replace('/' + store.slug);
    }
  }

  if (company.homeConfig.type === 'url') {
    if (ctx.res) {
      ctx.res.writeHead(302, {
        Location: company.homeConfig.url,
      });

      ctx.res.end();
    } else {
      window.location.href = company.homeConfig.url;
    }
  }

  return {
    company,
  };
};

const PageExtended = styled(Page)`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.main} 0%,
    ${(props) => darken(0.2, props.theme.colors.main)} 100%
  );
`;

const Stores = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 80vh;
  padding-top: 32px;
  @media ${(props) => props.theme.responsive.phone} {
    display: block;
  }
`;
const Logo = styled.img`
  display: block;
  max-width: 200px;
  max-height: 50px;
  margin: 0;
  margin-right: 24px;
  cursor: pointer;
`;
